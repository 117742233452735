<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title
        class="body-1"
        v-if="navigete != null && navigete != '' && navigete != 'null'"
      >
        การเงิน(ร้านค้า) <v-icon>mdi-chevron-right</v-icon>
        รายการร้านค้าแจ้งโอนเงิน
      </v-card-title>
    </v-card>

    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon
        ><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">สถานะ : </v-col>
          <v-col cols="12" md="4" class="pt-2">
            <v-radio-group v-model="radioStatus" row dense>
              <v-radio label="ทั้งหมด" :value="null"></v-radio>
              <v-radio label="รอตรวจสอบ" :value="TopupStatusDict['WaitCheck']"></v-radio>
              <v-radio label="ตรวจสอบแล้ว" :value="TopupStatusDict['Checked']"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-show="radioStatus != TopupStatusDict['WaitCheck']">
          <v-col cols="12" md="1" class="pt-2">วันที่ : </v-col>
          <v-col cols="12" md="4" lg="2" class="pt-2 mb-2" style="display: flex">
            <!-- <span class="pt-3 mr-3">เริ่ม :</span> -->
            <v-menu
              ref="menuStartDate"
              v-model="menuStartDate"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="startDate"
                  @change="(value) => (startDate = value)"
                  autocomplete="off"
                  label="วันที่เริ่มต้น"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  style="min-width: 200px; max-width: 700px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="(menuStartDate = false), $refs.menuStartDate.save(startDate)"
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" lg="2" class="pt-2 mb-2" style="display: flex">
            <!-- <span  class="pt-3 mr-3">ถึง :</span> -->
            <v-menu
              ref="menuEndDate"
              v-model="menuEndDate"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="200px"
              max-width="300px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="endDate"
                  @change="(value) => (endDate = value)"
                  autocomplete="off"
                  label="วันที่สิ้นสุด"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  style="min-width: 200px; max-width: 700px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="(menuEndDate = false), $refs.menuEndDate.save(endDate)"
                no-title
                :allowed-dates="allowedDates"
                class="my-0"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">ร้านค้า : </v-col>
          <v-col cols="12" md="4" class="pt-2">
            <v-autocomplete
              v-model="shop_id_by_name"
              ref="shop_id_by_name"
              :items="shop_id_by_name_items"
              :item-text="(item) => item.name"
              :item-value="(item) => item.id"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="1" class="pt-2">ยอดโอน : </v-col>
          <v-col cols="12" md="4" class="pt-2" style="display: flex">
            <v-text-field
              :value="transferAmount"
              @change="(value) => (transferAmount = value)"
              autocomplete="off"
              type="number"
              outlined
              hide-details
              dense
              style="min-width: 200px; max-width: 700px"
            ></v-text-field>
            <!-- <span  class="pt-3 ml-3">บาท</span> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="0" md="1" class="pt-2"> </v-col>
          <v-col cols="12" md="11" class="pt-2">
            <v-btn :elevation="1" @click="Searching()" color="info" class="mr-3 mt-1"
              ><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <v-icon size="20" color="success">fa-receipt</v-icon
        ><span class="my-3 ml-3">รายการโอนเงิน</span>
        <v-spacer />
        <vue-excel-xlsx
          id="GetExcel"
          :data="requestTopup.table"
          :columns="excel_topup_header"
          filename="รายการโอนเงิน"
          sheetname="shee1"
        >
          <v-btn color="success" outlined
            ><v-icon left size="20">mdi-file-excel</v-icon>export to excel</v-btn
          >
        </vue-excel-xlsx>
      </v-card-title>
      <v-divider />
      <v-card-text class="padding-mobile-display">
        <!-- แสดงจอใหญ่ -->
        <v-row class="hidden-xs-only mx-0 px-0">
          <v-col cols="12" class="mx-0 px-0">
            <v-data-table
              :headers="headers"
              :key="requestTopup.key_table"
              :items="requestTopup.table"
              :footer-props="footer_props"
              item-key="id"
              hide-default-footer
              :items-per-page="10000"
              class="packhai-border-table packhai-table"
            >
              <template v-slot:item.id="{ item }">
                {{ FormatTopup(item.id) }}
              </template>
              <template v-slot:item.fileUrl="{ item }">
                <v-img
                  style="margin: 5px"
                  v-if="item.fileUrl != null"
                  :src="item.fileUrl"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  width="50"
                  height="50"
                  @click="image_click(item.fileUrl)"
                ></v-img>
              </template>
              <template v-slot:item.bankName="{ item }">
                <div class="my-2">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.logo" />
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span
                  ><br />
                  <span>{{ item.accountNumber }}</span
                  ><br />
                  <span>{{ item.accountName }}</span>
                </div>
              </template>
              <template v-slot:item.approveOrReject="{ item }">
                <div v-if="item.approveDateTime != null">
                  <span>ตรวจสอบโดย {{ " " + item.aproveName + " " }}</span
                  ><br />
                  <span>วันที่ {{ " " + item.approveDateTime + " " }} </span>
                </div>
                <div v-else-if="item.approveDateTime == null && item.rejectBy != null">
                  <span>ปฏิเสธโดย {{ " " + item.rejectName + " " }}</span
                  ><br />
                  <span>วันที่ {{ " " + item.rejectDateTime + " " }} </span>
                </div>
              </template>
              <template v-slot:item.status="{ item }">
                <v-row>
                  <v-col cols="12" align="left" style="margin-left: 15px">
                    <span v-if="item.status == TopupStatusDict['Rejected']"
                      ><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon>
                      ปฎิเสธ</span
                    >
                    <span v-else-if="item.status == TopupStatusDict['WaitCheck']"
                      ><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon>
                      รอตรวจสอบ</span
                    >
                    <span v-else-if="item.status == TopupStatusDict['Checked']"
                      ><v-icon size="10" color="success"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                      ตรวจสอบแล้ว</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.action="{ item }">
                <div v-if="item.status == TopupStatusDict['WaitCheck']">
                  <v-btn
                    class="mx-2"
                    outlined
                    color="success"
                    @click="aprove_alert(item, false)"
                    >ตรวจยอดแล้ว</v-btn
                  >
                  <v-btn class="mx-2" outlined color="error" @click="check_reject(item)"
                    >ปฏิเสธ</v-btn
                  >
                </div>
                <div v-else>
                  <v-btn class="mx-2" disabled outlined>ตรวจยอดแล้ว</v-btn>
                  <v-btn class="mx-2" disabled outlined>ปฏิเสธ</v-btn>
                </div>
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatMoney(item.amount) }}
              </template>

              <template v-slot:item.transferDate="{ item }">
                {{ formatDatetime(item.transferDate) }}
              </template>

              <template v-slot:item.createDatetime="{ item }">
                {{ formatDatetime(item.createDatetime) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- แสดงจอมือถือ -->
        <v-card
          class="hidden-sm-and-up mx-0 mb-2"
          style="border-radius: 0px"
          elevation="0"
          v-for="(item, index) in requestTopup.table"
          :key="index"
        >
          <v-row class="pa-1 py-5 mx-0">
            <v-col cols="8" align="left">
              <span v-if="item.status == TopupStatusDict['Rejected']"
                ><v-icon size="10" color="red">mdi-checkbox-blank-circle</v-icon>
                ปฎิเสธ</span
              >
              <span v-else-if="item.status == TopupStatusDict['WaitCheck']"
                ><v-icon size="10" color="yellow">mdi-checkbox-blank-circle</v-icon>
                รอตรวจสอบ</span
              >
              <span v-else-if="item.status == TopupStatusDict['Checked']"
                ><v-icon size="10" color="success">mdi-checkbox-blank-circle</v-icon>
                ตรวจสอบแล้ว</span
              >
            </v-col>
            <v-col cols="4" align="right" v-if="item.fileUrl != null"
              ><a @click="image_click(item.fileUrl)">ดูสลิป</a></v-col
            >
          </v-row>
          <v-divider />
          <v-row class="pa-1 py-2 mx-0">
            <v-col cols="12">
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="12" align="left">
                  {{ item.shopName }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0 py-10" cols="12" align="center">
                  <span
                    ><h1>฿ {{ formatMoney(item.amount) }}</h1></span
                  >
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> เลขที่รายการ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ FormatTopup(item.id) }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> ธนาคาร : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span class="mr-1">
                    <v-avatar size="25px">
                      <img :src="item.logo" />
                    </v-avatar>
                  </span>
                  <span>{{ item.bankName }}</span
                  ><br />
                  <span>{{ item.accountNumber }}</span
                  ><br />
                  <span>{{ item.accountName }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> วันที่โอน : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ item.transferDate }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> วันที่แจ้งชำระ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ item.createDatetime }}
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left"> หมายเหตุ : </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  {{ item.remark }}
                </v-col>
              </v-row>
              <v-row
                class="mx-0 px-0 py-2"
                v-if="item.status == TopupStatusDict['Checked']"
              >
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ตรวจสอบโดย : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.aproveName }}</span>
                </v-col>
              </v-row>
              <v-row
                class="mx-0 px-0 py-2"
                v-if="item.status == TopupStatusDict['Checked']"
              >
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ตรวจสอบวันที่ : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.approveDateTime }}</span>
                </v-col>
              </v-row>
              <v-row
                class="mx-0 px-0 py-2"
                v-if="item.status == TopupStatusDict['Rejected']"
              >
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ปฎิเสธโดย : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.rejectName }}</span>
                </v-col>
              </v-row>
              <v-row
                class="mx-0 px-0 py-2"
                v-if="item.status == TopupStatusDict['Rejected']"
              >
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>ปฏิเสธวันที่ : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.rejectDateTime }}</span>
                </v-col>
              </v-row>
              <v-row class="mx-0 px-0 py-2">
                <v-col class="mx-0 px-0" cols="4" align="left">
                  <span>Reference No : </span>
                </v-col>
                <v-col class="mx-0 px-0" cols="8" align="right">
                  <span>{{ item.referenceNo }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            class="pa-0 mx-0 py-0"
            v-show="item.status == TopupStatusDict['WaitCheck']"
          >
            <v-col
              class="px-0 px-1"
              cols="6"
              align="center"
              style="border-right: 1px solid rgba(0, 0, 0, 0.12); height: 50px"
              ><v-btn
                text
                class="py-6"
                width="300px"
                color="success"
                @click="aprove_alert(item, true)"
                >ตรวจยอดแล้ว</v-btn
              ></v-col
            >
            <v-col class="px-0 px-1" cols="6" align="center" style="height: 50px"
              ><v-btn
                text
                class="py-6"
                width="300px"
                color="error"
                @click="check_reject(item)"
                >ปฏิเสธ
              </v-btn></v-col
            >
          </v-row>
        </v-card>

        <v-row class="mx-0 mt-2 background-mobile">
          <v-col class="mx-0" cols="0" md="6"> </v-col>
          <v-col class="mx-0 px-0" cols="12" md="6">
            <v-row class="mx-0 py-1 my-1">
              <v-col class="mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span
                  ><v-icon class="mr-1" size="10" color="red"
                    >mdi-checkbox-blank-circle</v-icon
                  ><b>ปฎิเสธ</b> :</span
                >
              </v-col>
              <v-col class="mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ sum_list["refuse"] }}</span
                ><span class="mx-2">บาท</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span
                  ><v-icon class="mr-1" size="10" color="yellow"
                    >mdi-checkbox-blank-circle</v-icon
                  ><b>รอตรวจสอบ</b> :</span
                >
              </v-col>
              <v-col class="mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ sum_list["waitCheck"] }}</span
                ><span class="mx-2">บาท</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span
                  ><v-icon class="mr-1" size="10" color="success"
                    >mdi-checkbox-blank-circle</v-icon
                  ><b>ตรวจสอบแล้ว</b> :</span
                >
              </v-col>
              <v-col class="mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ sum_list["checked"] }}</span
                ><span class="mx-2">บาท</span>
              </v-col>
            </v-row>
            <v-row class="mx-0 py-1 my-1">
              <v-col class="mx-0 padding-right-pc-0" cols="7" md="9" align="right">
                <span><b>รวมทั้งหมด</b> :</span>
              </v-col>
              <v-col class="mx-0 padding-right-pc-0" cols="5" md="3" align="right">
                <span>{{ sum_list["sumAmount"] }}</span
                ><span class="mx-2">บาท</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="aprove_dialog"
      scrollable
      :max-width="size_percent_for_mobile"
      persistent
    >
      <v-card style="background: #f0f0f0" class="my-0">
        <v-card-title>
          <span>รายละเอียดการโอน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="(aprove_dialog = false), ClearDataDialog()"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0" cols="12" md="3">
                  <h4>ธนาคาร :</h4>
                </v-col>
                <v-col class="pb-0" cols="12" md="9">
                  <v-select
                    ref="BanksID"
                    :items="BankList"
                    v-model="BanksID"
                    :item-value="(i) => i.id"
                    :item-text="(i) => i.fullBankName"
                    dense
                    hide-details
                    outlined
                  >
                    <template slot="selection" slot-scope="data">
                      <v-col cols="1" class="px-0" v-if="data.item.logo != null">
                        <v-avatar size="25px">
                          <img :src="data.item.logo" />
                        </v-avatar>
                      </v-col>
                      <v-col cols="11" class="mx-0">
                        {{ data.item.fullBankName }}
                      </v-col>
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-avatar size="25px" v-if="data.item.logo != null">
                        <img :src="data.item.logo" />
                      </v-avatar>
                      <v-col class="ml-3">
                        {{ data.item.fullBankName }}
                      </v-col>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0" cols="12" md="3">
                  <h4>วันที่โอน :</h4>
                </v-col>
                <v-col class="pb-0" cols="12" md="9">
                  <v-menu
                    ref="menu_date_transfer"
                    v-model="menu_date_transfer"
                    :close-on-content-click="false"
                    :return-value.sync="date_transfer"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computed_date_transfer"
                        @change="(value) => (computed_date_transfer = value)"
                        autocomplete="off"
                        prepend-inner-icon="mdi-calendar"
                        ref="dateRangeText"
                        outlined
                        readonly
                        v-on="on"
                        style="max-width: 350px"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_transfer"
                      no-title
                      scrollable
                      :allowed-dates="allowedDates"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="date_transfer = null"
                        >Reset</v-btn
                      >
                      <v-btn text color="primary" @click="menu_date_transfer = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu_date_transfer.save(date_transfer)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0" cols="12" md="3">
                  <h4>เวลา :</h4>
                </v-col>
                <v-col class="pb-0" cols="12" md="9">
                  <v-menu
                    ref="time_picker"
                    v-model="time_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="time"
                        @change="(value) => (time = value)"
                        autocomplete="off"
                        prepend-inner-icon="mdi-clock"
                        outlined
                        readonly
                        style="max-width: 350px"
                        v-on="on"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="time_picker"
                      v-model="time"
                      format="24hr"
                      full-width
                      @click:minute="$refs.time_picker.save(time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0" cols="12" md="3">
                  <h4>ยอด :</h4>
                </v-col>
                <v-col class="pb-0" cols="12" md="9">
                  <v-text-field
                    :value="approve_amount"
                    @change="(value) => (approve_amount = value)"
                    autocomplete="off"
                    ref="approve_amount"
                    type="number"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0" cols="12" md="3">
                  <h4>Reference No :</h4>
                </v-col>
                <v-col class="pb-0" cols="12" md="9">
                  <v-text-field
                    :value="approve_reference"
                    @change="(value) => (approve_reference = value)"
                    autocomplete="off"
                    ref="approve_reference"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col class="pb-0" cols="12" md="12">
              <v-row row>
                <v-col class="pb-0 my-2 hidden-xs-only" cols="12" md="12" align="right">
                  <v-btn
                    dark
                    color="primary"
                    @click="check_aprove(data_aprove)"
                    class="mr-1"
                    ><v-icon left>mdi-content-save</v-icon> ยืนยันยอดเงิน</v-btn
                  >
                </v-col>
                <v-col
                  class="pb-0 my-2 hidden-sm-and-up"
                  cols="12"
                  md="12"
                  align="center"
                >
                  <v-btn
                    dark
                    color="primary"
                    @click="check_aprove(data_aprove)"
                    class="mr-1"
                    ><v-icon left>mdi-content-save</v-icon> ยืนยันยอดเงิน</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_photo" width="600">
      <v-card class="pa-2" style="background: #f0f0f0">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-img :src="dialog_photo_link" aspect-ratio="1.3" contain></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import { branchService_dotnet, shopService_dotnet } from "@/website/global";
import { AlertWarning, AlertError } from "@/website/global_alert";
import { formatMoney, FormatTopup, formatDatetime } from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    page_loading: true,
    loading: false,

    TopupStatusDict: {
      WaitCheck: 1,
      Checked: 2,
      Rejected: 3,
    },

    navigete: [],

    dialog_photo: false,
    dialog_photo_link: null,

    footer_props: {
      "items-per-page-options": [50],
      "items-per-page-text": null,
      "disable-items-per-page": true,
    },
    headers: null,
    requestTopup: {
      headers: [
        {
          text: "จัดการ",
          align: "center",
          value: "action",
          width: "280px",
          sortable: false,
        },
        {
          text: "เลขที่รายการ",
          value: "id",
          width: "125px",
          align: "center",
          sortable: false,
        },
        { text: "วันที่โอน", value: "transferDate", width: "165px", sortable: false },
        { text: "ชื่อร้าน", value: "shopName", width: "250px", sortable: false },
        { text: "ธนาคาร", value: "bankName", width: "250px", sortable: false },
        { text: "สลิป", align: "center", value: "fileUrl", sortable: false },
        {
          text: "จำนวนเงิน",
          value: "amount",
          sortable: false,
          width: "130px",
          align: "right",
        },
        { text: "หมายเหตุ", value: "remark", sortable: false },
        {
          text: "วันที่แจ้งชำระ",
          value: "createDatetime",
          width: "165px",
          sortable: false,
        },
        {
          text: "สถานะ",
          value: "status",
          sortable: false,
          width: "160px",
          align: "center",
        },
        // { text: 'ตรวจสอบ/ปฏิเสธ', value: 'ApproveOrReject', sortable: false, 'width': '300px', align: 'left' },
      ],
      headers2: [
        {
          text: "จัดการ",
          align: "center",
          value: "action",
          width: "280px",
          sortable: false,
        },
        {
          text: "เลขที่รายการ",
          value: "id",
          width: "125px",
          align: "center",
          sortable: false,
        },
        { text: "วันที่โอน", value: "transferDate", width: "165px", sortable: false },
        { text: "ชื่อร้าน", value: "shopName", width: "250px", sortable: false },
        { text: "ธนาคาร", value: "bankName", width: "250px", sortable: false },
        { text: "สลิป", align: "center", value: "fileUrl", sortable: false },
        {
          text: "จำนวนเงิน",
          value: "amount",
          sortable: false,
          width: "130px",
          align: "right",
        },
        { text: "หมายเหตุ", value: "remark", sortable: false },
        {
          text: "วันที่แจ้งชำระ",
          value: "createDatetime",
          width: "165px",
          sortable: false,
        },
        {
          text: "สถานะ",
          value: "status",
          sortable: false,
          width: "160px",
          align: "center",
        },
        {
          text: "Reference No",
          value: "referenceNo",
          sortable: false,
          width: "160px",
          align: "center",
        },
        {
          text: "ตรวจสอบ/ปฏิเสธ",
          value: "approveOrReject",
          sortable: false,
          width: "300px",
          align: "left",
        },
      ],
      table: [],
      key_table: 0,
    },

    // Searching
    radioStatus: null,
    transferAmount: null,
    shop_id_by_name: null,
    shop_id_by_name_items: null,
    sum_list: null,

    searchDataList: null,
    startDate: null,
    // startDate: new Date().toISOString().substr(0, 10),
    menuStartDate: false,

    endDate: null,
    // endDate: new Date().toISOString().substr(0, 10),
    menuEndDate: false,

    // dialog aprove_alert
    size_percent_for_mobile: "45%",
    aprove_dialog: false,
    isFullscreen: false,
    approve_transferDate: null,
    approve_amount: null,
    approve_reference: null,
    data_aprove: null,
    BanksID: null,
    BankList: null,

    excel_topup_header: [
      { label: "เลขที่รายการ", field: "id_des" },
      { label: "วันที่โอน", field: "transferDate" },
      { label: "ชื่อร้าน", field: "shopName" },
      { label: "ธนาคาร", field: "fullBankName" },
      { label: "สลิป", field: "fileUrl" },
      { label: "จำนวนเงิน", field: "amount" },
      { label: "หมายเหตุ", field: "remark" },
      { label: "วันที่แจ้งชำระ", field: "createDatetime" },
      { label: "สถานะ", field: "statusDecription" },
    ],

    // date
    menu_date_transfer: false,
    date_transfer: null,

    // time picker
    time: null,
    time_picker: false,
  }),
  watch: {
    radioStatus: function (val) {
      if (val != 1) {
        this.headers = this.requestTopup.headers2;
      } else {
        this.headers = this.requestTopup.headers;
      }
    },
  },
  computed: {
    computed_search_sent_date() {
      if (this.date_range[0] != null) {
        return this.formatDate(this.date_range);
      } else {
        return null;
      }
    },
    computed_date_transfer() {
      return this.formatDateTransfer(this.date_transfer);
    },
  },
  async created() {
    this.headers = this.requestTopup.headers;
    document.title = this.$router.history.current.meta.title;
    this.radioStatus = this.TopupStatusDict["WaitCheck"];
    this.navigete = JSON.parse(localStorage.getItem("navigete"));
    this.shop_id_by_name = null;
    await this.get_branch_bank_account_list();
    await this.get_shop_list();
    await this.get_request_new();
    this.page_loading = false;
  },
  methods: {
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    image_click(photo_link) {
      this.dialog_photo_link = photo_link;
      this.dialog_photo = true;
    },

    formatDateTransfer(date) {
      if (date == null) return null;
      if (date != null) {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    getFirstDateAndToDate() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
      var toDay = new Date().toISOString().substr(0, 10);
      firstDay = firstDay.toISOString().substr(0, 10);
      this.startDate = firstDay;
      this.endDate = toDay;
    },

    async get_shop_list() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        {
          branchId: localStorage.getItem("Branch_BranchID"),
        },
        { headers: this.header_token }
      );
      var data_tmp = response.data.result;
      this.shop_id_by_name_items = [];
      for (var item of data_tmp) {
        if (!item.shop.isDelete) this.shop_id_by_name_items.push(item.shop);
      }

      this.shop_id_by_name_items.splice(0, 0, {
        name: "-- ร้านค้าทั้งหมด --",
        id: null,
      });
    },

    async get_branch_bank_account_list() {
      try {
        let response = await axios.post(
          branchService_dotnet + "Branch/get-branch-bank-account-list",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
          },
          { headers: this.header_token }
        );
        this.BankList = response.data;

        for (var item of this.BankList) {
          if (item.accountNumber != null && item.accountNumber != "") {
            item.fullBankName =
              item.bankName + " : " + item.accountNumber + " : " + item.accountName;
          } else {
            item.fullBankName = item.bankName;
          }
        }
        var type_tmp = { id: null, fullBankName: " -- กรุณาเลือก -- " };
        this.BankList.unshift(type_tmp);
      } catch (e) {
        this.$swal({
          type: "warning",
          title: "get-branch-bank-account-list เกิดข้อผิดพลาดบางอย่าง!!",
          text: e.message,
        });
      }
    },

    async Searching() {
      await this.get_request_new();
    },

    async get_request_new() {
      if (this.transferAmount == null || this.transferAmount == "") {
        this.transferAmount = null;
      }

      this.loading = true;
      let response = await axios.post(
        branchService_dotnet + "Topup/get-shop-topup-list",
        {
          branchID: localStorage.getItem("Branch_BranchID"),
          shopID: this.shop_id_by_name,
          status: this.radioStatus,
          amount: this.transferAmount,
          dateFrom: this.startDate,
          dateTo: this.endDate,
        },
        { headers: this.header_token }
      );
      for (var items of response.data.topup_list) {
        items["id_des"] = FormatTopup(items.id);
        items["fullBankName"] =
          items.nameThai + " " + items.accountNumber + " " + items.accountName;
      }
      this.requestTopup["table"] = response.data.topup_list;
      this.requestTopup["key_table"]++;
      this.sum_list = response.data.sum_data;

      this.startDate = response.data.dateFrom;
      this.endDate = response.data.dateTo;

      this.loading = false;
    },

    aprove_alert(item, isFullscreen) {
      this.aprove_dialog = true;
      this.isFullscreen = isFullscreen;
      if (this.isFullscreen == true) {
        this.size_percent_for_mobile = "90%";
      } else {
        this.size_percent_for_mobile = "45%";
      }
      this.data_aprove = item;

      // this.approve_transferDate = item['TransferDate']
      this.approve_amount = item["amount"];
      this.approve_reference = item["referenceNo"];
      this.BanksID = item["branchBankAccountID"];

      var datetime = item["transferDate"];
      this.time = datetime.substring(11, 16);
      var dd = datetime.substring(8, 10);
      var mm = datetime.substring(5, 7);
      var yy = datetime.substring(0, 4);
      this.date_transfer = yy + "-" + mm + "-" + dd;
    },

    ClearDataDialog() {
      // this.approve_transferDate = null
      this.approve_amount = null;
      this.BanksID = null;
      this.approve_reference = null;
      this.time = null;
      this.date_transfer = null;
    },

    check_aprove(item) {
      var dataDialog = {
        BanksID: this.BanksID,
        approve_transferDate: this.date_transfer + " " + this.time + ":00",
        Amount: this.approve_amount,
        ReferenceNo: this.approve_reference,
      };
      this.approve(item, dataDialog);
    },

    async approve(item, dataDialog) {
      this.loading = true;
      let response = await axios.post(
        branchService_dotnet + "Topup/topup-shop-approve",
        {
          topupID: item.id,
          shopID: item.shopID,
          staffID: localStorage.getItem("Branch_StaffID"),
          amount: dataDialog.Amount,
          referenceNo: dataDialog.ReferenceNo,
          transferDate: dataDialog.approve_transferDate,
          branchBankAccountID: dataDialog.BanksID,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      this.aprove_dialog = false;
      this.get_request_new();
    },

    check_reject(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        title:
          "ปฏิเสธยอด " +
          item["amount"] +
          " จากร้านค้า " +
          item["shopName"] +
          " ใช่หรือไม่!!",
        // text: 'ปฏิเสธ ใช่หรือไม่!!',
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.reject(item);
        }
      });
    },

    async reject(item) {
      this.loading = true;
      let response = await axios.post(
        branchService_dotnet + "Topup/reject-shop-topup",
        {
          topupID: item.id,
          staffID: localStorage.getItem("Branch_StaffID"),
        },
        { headers: this.header_token }
      );
      this.get_request_new();
      this.loading = false;
    },

    formatMoney,
    FormatTopup,
    AlertWarning,
    AlertError,
    formatDatetime,
  },
};
</script>

<style scoped>
.packhai-border-table {
  /* border:1px solid #e9edf4; */
  border-collapse: collapse;
  border-spacing: 0px;
  border-right: 1px solid #e9edf4;
  border-bottom: 1px solid #e9edf4;
  border-top: 1px solid #e9edf4;
}
.packhai-border-table thead {
  color: white;
}
.packhai-border-table thead tr th {
  color: black !important;
  font-weight: bold;
  font-size: 14px;
}
.packhai-border-table tr {
  /* border-collapse: collapse; */
  /* border: 1px solid #e9edf4; */
}
.packhai-border-table th {
  /* border-collapse: collapse; */
  border-left: 1px solid #e9edf4;
}
.packhai-border-table tr td {
  border-left: 1px solid #e9edf4;
}
/* จอเล็กไม่มี margin */
.padding-mobile-display {
  padding-left: 17.5px !important;
  padding-right: 17.5px !important;
}
.padding-right-pc-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media (max-width: 599px) {
  .padding-mobile-display {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #f0f0f0;
  }
  .background-mobile {
    background: #fff;
  }
  .padding-right-pc-0 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
</style>
